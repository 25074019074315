import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202')
];

export const server_loads = [0,2,3,4,5,6,7,8,9];

export const dictionary = {
		"/": [~10],
		"/about-us": [11],
		"/account-setting": [~12],
		"/auth-error": [13],
		"/bet-history": [~14],
		"/bet-history/extra": [15],
		"/bet-history/search": [16],
		"/bet-slip": [~17],
		"/bet-slip/empty-bet-slip": [19],
		"/bet-slip/empty-my-bet": [20],
		"/bet-slip/login": [21],
		"/bet-slip/settings": [22],
		"/bet-slip/[id]/bet-accepted": [~18],
		"/blog": [23],
		"/blog/[id]": [24],
		"/bonuses": [~25],
		"/cashback": [~26],
		"/casino-slot": [27,[2]],
		"/casino-slot/more-games/[id]": [28,[2]],
		"/casino-slot/play-game/[id]": [~29,[2]],
		"/casino-slot/preview-games/[id]": [30,[2]],
		"/deposit": [~31],
		"/deposit/bank-card": [32],
		"/deposit/bank-card/form": [33],
		"/deposit/bank-card/not-supported": [34],
		"/deposit/bank-transfer": [35],
		"/deposit/bank-transfer/approved": [36],
		"/deposit/bank-transfer/complete": [~37],
		"/deposit/bank-transfer/form": [~38],
		"/deposit/bank-transfer/waiting": [~39],
		"/deposit/ewallet": [40],
		"/deposit/ewallet/form": [41],
		"/deposit/ewallet/not-supported": [42],
		"/deposit/nexusx-on-tron": [46],
		"/deposit/nexusx-on-tron/complete": [47],
		"/deposit/nexusx-on-tron/next": [~48],
		"/deposit/nexusx-on-tron/waiting": [49],
		"/deposit/nexusx": [43],
		"/deposit/nexusx/complete": [44],
		"/deposit/nexusx/waiting": [45],
		"/deposit/tether-on-tron": [50],
		"/deposit/tether-on-tron/complete": [51],
		"/deposit/tether-on-tron/confirm": [52],
		"/deposit/tether-on-tron/waiting": [53],
		"/esports": [~54],
		"/faqs": [55],
		"/favorites": [~56],
		"/invite": [~57],
		"/invite/description": [58],
		"/invite/term-and-condition": [59],
		"/live-casino": [60,[3]],
		"/live-casino/game-lobby/[gameLobby]": [~61,[3]],
		"/live-casino/preview-live/[id]": [62,[3]],
		"/live-casino/select-lobby/[selectLobby]": [63,[3]],
		"/log-in": [~64],
		"/log-in/by-email": [~65],
		"/log-in/by-email/complete": [66],
		"/log-in/by-phone": [~67],
		"/log-in/by-phone/complete": [68],
		"/log-in/by-phone/enter-code": [69],
		"/log-in/by-pre-telegram": [70],
		"/log-in/by-telegram": [~71],
		"/log-in/by-telegram/complete": [72],
		"/log-in/error": [73],
		"/log-out": [~74],
		"/lottery": [75,[4]],
		"/lottery/play-game/[id]": [76,[4]],
		"/lottery/preview-games/[id]": [77,[4]],
		"/mailing": [~78],
		"/menu": [~79],
		"/messages": [~80],
		"/messages/contact-us": [~81],
		"/messages/message-details/[id]": [~82],
		"/mini-game": [83,[5]],
		"/mini-game/play-game/[id]": [84,[5]],
		"/mini-game/preview-games/[id]": [85,[5]],
		"/nexusx": [86],
		"/notifications": [~87],
		"/profile/account": [~88],
		"/profile/account/edit-main-currencies": [~89],
		"/profile/account/select-currency": [90],
		"/profile/account/select-language": [91],
		"/profile/personal": [92],
		"/profile/settings/account": [93],
		"/promocode": [~94],
		"/promotion": [~95],
		"/recent-game": [~96],
		"/result": [~97],
		"/result/filter": [98],
		"/security": [~99],
		"/security/email-login": [100],
		"/security/enter-code": [101],
		"/security/history": [~102],
		"/security/link-phone": [103],
		"/security/phone-activation": [104],
		"/security/security-question": [105],
		"/security/two-auth": [106],
		"/select-language": [107],
		"/sign-up": [~108],
		"/sign-up/by-email": [~109],
		"/sign-up/by-email/complete": [110],
		"/sign-up/by-email/nick-name": [~111],
		"/sign-up/by-phone": [~112],
		"/sign-up/by-phone/complete": [113],
		"/sign-up/by-phone/enter-code": [~114],
		"/sign-up/by-phone/nick-name": [~115],
		"/sign-up/by-pre-telegram": [116],
		"/sign-up/by-telegram": [~117],
		"/sign-up/by-telegram/complete": [118],
		"/sign-up/by-telegram/nick-name": [~119],
		"/site-settings": [120],
		"/sports-setting": [131],
		"/sports-setting/[id]/prematch-sports": [132],
		"/sports": [~121],
		"/sports/bet": [~124],
		"/sports/bet/history": [~126],
		"/sports/bet/[id]": [~125],
		"/sports/filter-market": [127],
		"/sports/filters": [128],
		"/sports/market-settings": [129],
		"/sports/search": [130],
		"/sports/[id]/eventinfo": [~123],
		"/sports/[id]/event": [~122],
		"/terms-and-conditions": [134],
		"/terms": [~133],
		"/time-zone": [135],
		"/transaction-history": [~136],
		"/user": [~137],
		"/web": [~138,[6]],
		"/web/about-us": [139,[6]],
		"/web/bet-slip-check": [140,[6]],
		"/web/bet-via-telegram": [141,[6]],
		"/web/blog": [142,[6]],
		"/web/blog/[id]": [143,[6]],
		"/web/contact-us": [144,[6]],
		"/web/desktop-app": [145,[6]],
		"/web/esports": [~146,[6]],
		"/web/faqs": [147,[6]],
		"/web/games/casino": [149,[6,7]],
		"/web/games/lottery": [150,[6,7]],
		"/web/games/mini-game": [151,[6,7]],
		"/web/games/slots": [152,[6,7]],
		"/web/games/[providerId]/play-game/[id]": [~148,[6,7]],
		"/web/mobile-app": [153,[6]],
		"/web/profile": [~154,[6,8]],
		"/web/profile/account-setting": [155,[6,8]],
		"/web/profile/bet-history": [156,[6,8]],
		"/web/profile/bonuses": [157,[6,8]],
		"/web/profile/cashback": [158,[6,8]],
		"/web/profile/deposit": [159,[6,8]],
		"/web/profile/edit": [~160,[6,8]],
		"/web/profile/invite": [~161,[6,8]],
		"/web/profile/log-out": [~162,[6,8]],
		"/web/profile/mailing": [163,[6,8]],
		"/web/profile/messages": [~164,[6,8]],
		"/web/profile/messages/contact-us": [165,[6,8]],
		"/web/profile/my-account": [~166,[6,8]],
		"/web/profile/promo-check": [167,[6,8]],
		"/web/profile/promotion": [168,[6,8]],
		"/web/profile/security": [~169,[6,8]],
		"/web/profile/sports-setting": [170,[6,8]],
		"/web/profile/transaction-history": [171,[6,8]],
		"/web/profile/withdraw": [172,[6,8]],
		"/web/promotion": [173,[6]],
		"/web/sports/live": [~178,[6]],
		"/web/sports/prematch": [~179,[6]],
		"/web/sports/[id]/event": [~174,[6]],
		"/web/sports/[id]/rankings": [175,[6]],
		"/web/sports/[id]/standings": [176,[6]],
		"/web/sports/[id]/summary": [~177,[6]],
		"/web/statistics/results": [~180,[6,9]],
		"/web/statistics/statistic": [~181,[6,9]],
		"/web/terms-and-conditions": [182,[6]],
		"/welcome": [183],
		"/withdrawal": [~184],
		"/withdrawal/bank-card": [185],
		"/withdrawal/bank-card/form": [186],
		"/withdrawal/bank-card/not-supported": [187],
		"/withdrawal/bank-transfer": [188],
		"/withdrawal/bank-transfer/approved": [189],
		"/withdrawal/bank-transfer/complete": [190],
		"/withdrawal/bank-transfer/form": [~191],
		"/withdrawal/bank-transfer/waiting": [192],
		"/withdrawal/error": [193],
		"/withdrawal/ewallet": [194],
		"/withdrawal/ewallet/form": [195],
		"/withdrawal/ewallet/not-supported": [196],
		"/withdrawal/nexusx": [~197],
		"/withdrawal/nexusx/approved": [198],
		"/withdrawal/nexusx/complete": [199],
		"/withdrawal/tether-on-tron": [~200],
		"/withdrawal/tether-on-tron/approved": [201],
		"/withdrawal/tether-on-tron/complete": [202]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';